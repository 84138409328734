import React, { useRef, useState } from 'react';
import arrow from '../../images/home/arrow.png';
import emailjs from '@emailjs/browser';
import Popup from '../../shared/Popup';
import ReCAPTCHA from "react-google-recaptcha";

function ReferCustomerComponent() {

    const [buttonPopup, setButtonPopup] = useState(false);
    const [capVal, setCapVal] = useState(null);
    const recaptchaRef = useRef(null);

    function sendEmail(e) {
        e.preventDefault();

        emailjs.sendForm('service_7rdbwzn', 'template_rpupsx3', e.target, 'user_aAx63STK8JFSdqbKPIGOv')
            .then((result) => {
                console.log(result.text);
                setButtonPopup(true);
            }, (error) => {
                console.log(error.text);
            });
        e.target.reset();
        // Reset reCAPTCHA
        if (recaptchaRef.current) {
            recaptchaRef.current.reset();
        }
    }

    return (
        <div style={{ paddingTop: '10vh', display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
            <div style={{ textAlign: 'center', color: '#143142', fontSize: 40, fontFamily: 'Rubik', fontWeight: '500' }}>REFER A FRIEND</div>
            <div style={{ padding: '2%', margin: '2%', background:' #FBFBFB', borderRadius: '20px', border: '1px #F0F0F0 solid', width: '50%' }}>
                <div style={{ color: '#244455', fontSize: 16, fontFamily: 'Karla', fontWeight: '500' }}>Complete the form below, and our team will be in touch with you shortly.</div>
                <br />
                <form onSubmit={sendEmail} id="form1">
                    <div style={{ color: '#244455', fontSize: 18, fontFamily: 'Karla', fontWeight: 'bold', marginBottom: '1vh' }}>Your Details</div>
                    <div style={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'space-between' }}>
                        <div style={{ display: 'flex', flexDirection: 'column' }}>
                            <label style={{ color: 'black', fontFamily: 'Karla', fontSize: 14 }}>First Name <span style={{ color: '#FF0000', fontSize: 16, fontFamily: 'Karla', fontWeight: '400' }}>*</span></label>
                            <input
                                className="firstName input-text input-row1"
                                type="text"
                                name="YourFirst"
                                required
                                style={{ width: '280px', opacity: 0.90, background: 'rgba(248, 248, 248, 0.30)', borderRadius: 5, border: '1px #CBCBCB solid', color: 'black', padding: '1%' }}
                            />
                        </div>
                        <div style={{ display: 'flex', flexDirection: 'column' }}>
                            <label style={{ color: 'black', fontFamily: 'Karla', fontSize: 14 }}> Last Name </label>
                            <input
                                className="lastName input-text input-row1"
                                type="text"
                                name="YourLast"
                                style={{ width: '280px', opacity: 0.90, background: 'rgba(248, 248, 248, 0.30)', borderRadius: 5, border: '1px #CBCBCB solid', color: 'black', padding: '1%' }}
                            />
                        </div>
                    </div>
                    <hr style={{marginTop: '3vh', marginBottom: '3vh'}} />
                    <div style={{ color: '#244455', fontSize: 18, fontFamily: 'Karla', fontWeight: 'bold', marginBottom: '1vh' }}>Refferal Details</div>
                    <div style={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'space-between' }}>
                        <div style={{ display: 'flex', flexDirection: 'column' }}>
                            <label style={{ color: 'black', fontFamily: 'Karla', fontSize: 14 }}>First Name <span style={{ color: '#FF0000', fontSize: 16, fontFamily: 'Karla', fontWeight: '400' }}>*</span></label>
                            <input
                                className="firstName input-text input-row1"
                                type="text"
                                name="ReferFirst"
                                required
                                style={{ width: '280px', opacity: 0.90, background: 'rgba(248, 248, 248, 0.30)', borderRadius: 5, border: '1px #CBCBCB solid', color: 'black', padding: '1%' }}
                            />
                        </div>
                        <div style={{ display: 'flex', flexDirection: 'column' }}>
                            <label style={{ color: 'black', fontFamily: 'Karla', fontSize: 14 }}> Last Name </label>
                            <input
                                className="lastName input-text input-row1"
                                type="text"
                                name="ReferLast"
                                style={{ width: '280px', opacity: 0.90, background: 'rgba(248, 248, 248, 0.30)', borderRadius: 5, border: '1px #CBCBCB solid', color: 'black', padding: '1%' }}
                            />
                        </div>
                    </div>
                    <br />
                    <div style={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'space-between' }}>
                        <div style={{ display: 'flex', flexDirection: 'column' }}>
                            <label style={{ color: 'black', fontFamily: 'Karla', fontSize: 14 }}> Email Id</label>
                            <input
                                className="Email input-text"
                                type="email"
                                name="Email"
                                style={{ width: '280px', opacity: 0.90, background: 'rgba(248, 248, 248, 0.30)', borderRadius: 5, border: '1px #CBCBCB solid', color: 'black', padding: '1%' }}
                            />
                        </div>
                        <div style={{ display: 'flex', flexDirection: 'column' }}>
                            <label style={{ color: 'black', fontFamily: 'Karla', fontSize: 14 }}> Phone Number</label>
                            <input
                                className="phno input-text"
                                type="tel"
                                name="Phone"
                                style={{ width: '280px', opacity: 0.90, background: 'rgba(248, 248, 248, 0.30)', borderRadius: 5, border: '1px #CBCBCB solid', color: 'black', padding: '1%' }}
                            />
                        </div>
                    </div>
                    <br />
                    <br />
                    <ReCAPTCHA
                        ref={recaptchaRef}
                        sitekey="6LciBywpAAAAADaQ-LhY931ukbQ6yD4KaiNVfZ0x"
                        onChange={(val) => setCapVal(val)}
                    />
                    <br />
                    <button disabled={!capVal} style={{ background: '#143141', borderRadius: 24, display: 'flex', alignItems: 'center', width: '150px', color: 'white', justifyContent: 'center' }} className="btn" type='submit'>
                        Submit
                        <img src={arrow} style={{ height: 'auto', width: '14px', marginLeft: '5%' }} alt='arrow' />
                    </button>
                </form>
            </div>
            <Popup trigger={buttonPopup} setTrigger={setButtonPopup}>
                <h3>Message sent successfully!</h3>
                <p>Team Aethos will get back to you soon.</p>
            </Popup>
        </div>
    )
}

export default ReferCustomerComponent