import './App.css';
import { Routes, Route } from "react-router-dom";
import NavbarComponent from './shared/Navbar.component';
import WhatIsAethosComponent from './WhatIsAethos/components/WhatIsAethos.component';
import FooterComponent from './shared/Footer.component';
import HomeComponent from './Home/components/Home.component';
import WhatWeOfferComponent from './WhatWeOffer/components/WhatWeOffer.component';
import TeamComponent from './Team/components/Team.component';
import ContactusComponent from './ContactUs/component/Contactus.component';
import BookAppointmentComponent from './BookAppointment/components/BookAppointment.component';
import HealthSafetyComponent from './HealthSafety/components/HealthSafety.component';
import PrivacyPolicyComponent from './PrivacyPolicy/components/PrivacyPolicy.component';
import TermsOfServiceComponent from './TermsOfService/components/TermsOfService.component';
import ScrollToTop from './shared/ScrollToTop';
import ReferCustomerComponent from './ReferCustomer/components/ReferCustomer.component';

function App() {
  return (
    <>
      <NavbarComponent />
      <ScrollToTop>
        <Routes>
          <Route path="/" element={<HomeComponent />} />
          <Route path="/what-is-aethos" element={<WhatIsAethosComponent />} />
          <Route path="/what-we-offer" element={<WhatWeOfferComponent />} />
          <Route path="/team" element={<TeamComponent />} />
          <Route path="/contact-us" element={<ContactusComponent />} />
          <Route path="/request-appointment" element={<BookAppointmentComponent />} />
          <Route path="/refer-customer" element={<ReferCustomerComponent />} />
          <Route path="/health-safety" element={<HealthSafetyComponent />} />
          <Route path='/privacy-policy' element={<PrivacyPolicyComponent />} />
          <Route path='/terms-of-service' element={<TermsOfServiceComponent />} />
        </Routes>
      </ScrollToTop>
      <FooterComponent />
    </>
  );
}

export default App;
